import { Reducer, combineReducers } from 'redux'
import { HaynesTechnicalData, IAccount, ILatestOdometerLog, IMOTHistory, IRDWTransactionLogItem, IVehicle } from '@cbgms/api'
import { createReducer } from '@cbgms/base/utils/redux'
import * as typeConsts from './types'
import serviceSchedule from './serviceSchedule'
import lubricants from 'app/vehicles/state/fluidLookup/reducers'
import {
  GET_WORKORDER_WITH_RELATED_SUCCESS,
  LOAD_ACCOUNT_WORKORDERS_SUCCESS,
  LOAD_VEHICLE_WORKORDERS_SUCCESS
} from 'app/workorder/state/workorder/types'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { IVehicleDamage } from '@cbgms/api/modules/vehicle-damages'
import { GET_ORDER_WITH_RELATED_SUCCESS } from 'app/order/state/types'

type DetailReducerState = Readonly<IVehicle>

const defaultState: DetailReducerState = {} as any

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type DetailReducerBehavior<S> = Record<typeof typeConsts.LOAD_VEHICLE_SUCCESS, Reducer<S, any>> &
  Record<typeof typeConsts.SET_VEHICLE_DETAIL, Reducer<S, any>> &
  Record<typeof typeConsts.RESET_VEHICLE_DETAIL, Reducer<S, any>> &
  Record<typeof GET_WORKORDER_WITH_RELATED_SUCCESS, Reducer<S, any>> &
  Record<typeof GET_ORDER_WITH_RELATED_SUCCESS, Reducer<S, any>> &
  Record<typeof typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS, Reducer<S, any>>

const detail = createReducer<DetailReducerState, DetailReducerBehavior<DetailReducerState>>(defaultState, {
  [typeConsts.LOAD_VEHICLE_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [typeConsts.RESET_VEHICLE_DETAIL]: () => {
    return defaultState
  },
  [typeConsts.SET_VEHICLE_DETAIL](_, { vehicle }) {
    return vehicle
  },
  [GET_WORKORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.Vehicle || defaultState
  },
  [GET_ORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.Vehicle || defaultState
  },
  [typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.Vehicle || defaultState
  }
})

export type DefaultAccountState = IAccount | null
const defaultAccountsState: DefaultAccountState = null

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type AccountBehavior<S> = Record<typeof typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS, Reducer<S, any>> &
  Record<typeof GET_WORKORDER_WITH_RELATED_SUCCESS, Reducer<S, any>> &
  Record<typeof GET_ORDER_WITH_RELATED_SUCCESS, Reducer<S, any>> &
  Record<typeof typeConsts.RESET_VEHICLE_DETAIL, Reducer<S, any>>

const account = createReducer<DefaultAccountState, AccountBehavior<DefaultAccountState>>(defaultAccountsState, {
  [typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.Account
  },
  [GET_WORKORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleAccount
  },
  [GET_ORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleAccount
  },
  [typeConsts.RESET_VEHICLE_DETAIL]: () => {
    return defaultAccountsState
  }
})

const companyAccount = createReducer<DefaultAccountState, AccountBehavior<DefaultAccountState>>(defaultAccountsState, {
  [typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.CompanyAccount
  },
  [GET_WORKORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleCompanyAccount
  },
  [GET_ORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleCompanyAccount
  },
  [typeConsts.RESET_VEHICLE_DETAIL]: () => {
    return defaultAccountsState
  }
})

const leaseCompanyAccount = createReducer<DefaultAccountState, AccountBehavior<DefaultAccountState>>(defaultAccountsState, {
  [typeConsts.GET_VEHICLE_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.LeaseCompanyAccount
  },
  [GET_WORKORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleLeaseCompanyAccount
  },
  [GET_ORDER_WITH_RELATED_SUCCESS](_, { payload }) {
    const data = payload && payload.data && payload.data.Data

    return data.VehicleLeaseCompanyAccount
  },
  [typeConsts.RESET_VEHICLE_DETAIL]: () => {
    return defaultAccountsState
  }
})

const workorders = createReducer([], {
  [LOAD_VEHICLE_WORKORDERS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [LOAD_ACCOUNT_WORKORDERS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

const defaultTechnicalData: HaynesTechnicalData[] = []

const technicalData = createReducer(defaultTechnicalData, {
  [typeConsts.LOAD_HAYNESPRO_ADJUSTMENTDATA_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [typeConsts.RESET_HAYNESPRO_DATA]: () => {
    return []
  }
})

const manualsList = createReducer([], {
  [typeConsts.LOAD_HAYNESPRO_TECHNICALMANUALS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [typeConsts.RESET_HAYNESPRO_DATA]: () => {
    return []
  }
})

const manualDetails = createReducer(
  {},
  {
    [typeConsts.LOAD_HAYNESPRO_TECHNICALMANUAL_SUCCESS]: (state, { payload, request }) => {
      return {
        ...state,
        [request.storyID]: payload.data.Data
      }
    },
    [typeConsts.RESET_HAYNESPRO_DATA]: () => {
      return {}
    }
  }
)

const defaultOdometerLogState: Partial<ILatestOdometerLog> = {}

const odoMeterLog = createReducer(defaultOdometerLogState, {
  [typeConsts.LOAD_ODOMETER_LOG_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [typeConsts.RESET_ODOMETER_LOG]: () => {
    return {}
  }
})

const defaultAPKHistoryState: ReadonlyArray<IRDWTransactionLogItem> = []

const apkHistory = createReducer(defaultAPKHistoryState, {
  [typeConsts.GET_APK_HISTORY_SUCCESS]: (_, { payload }) => {
    const data: IRDWTransactionLogItem[] = (payload && payload.data && payload.data.Data) || defaultAPKHistoryState

    return data
  }
})

const defaultMOTHistoryState: Partial<IMOTHistory> = {}

const MOTHistory = createReducer(defaultMOTHistoryState, {
  [typeConsts.GET_MOT_HISTORY_SUCCESS]: (_, { payload }) => {
    const data: IMOTHistory = (payload && payload.data && payload.data.Data) || defaultMOTHistoryState

    return data
  }
})

const defaultDamageState: IVehicleDamage = {
  UUID: EmptyUUID,
  Description: '',
  DamagedAt: ''
}

const damage = createReducer(defaultDamageState, {
  [typeConsts.GET_VEHICLE_DAMAGE_SUCCESS]: (_, { payload }) => {
    return (payload && payload.data && payload.data.Data && payload.data.Data) || defaultDamageState
  }
})

const manuals = combineReducers({
  list: manualsList,
  detail: manualDetails
})

const haynes = combineReducers({
  manuals,
  technicalData
})

// vehicleTypeSelectorState is used to trigger the vehicle type selector from redux.
// prefer to use 'useShowVehicleTypeSelector' instead unless it's not possible

const vehicle = combineReducers({
  account,
  apkHistory,
  companyAccount,
  leaseCompanyAccount,
  detail,
  odoMeterLog,
  haynes,
  lubricants,
  workorders,
  serviceSchedule,
  MOTHistory,
  damage
})

export default vehicle
